.container {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
}
.pne-container {
    border-bottom: 1px solid var(--shadow-gray);
    border-top: 1px solid var(--shadow-gray);
    font-family: 'Roboto', sans-serif;
    padding: 1rem 4rem 4rem 4rem;
    max-width: 1300px;
    margin: 0 auto;
}

.pne-container h3 {
    background-color: var(--white);
    color: var(--sky-blue);
    font-size: 1.5rem;
    font-weight: lighter;
    padding: 0 1rem;
    position: relative;
    top: -2.875rem;
    display: inline-block;
}

.pne-container h1 {
    font-weight: normal;
    font-size: 1.75rem;
    margin: 0 auto;
    max-width: 1055px;
}

.pne-container strong {
    color: var(--sky-blue);
}

.pne-form {
    display: flex;
    align-items: center;
    font-weight: lighter;
    margin: 2rem auto;
    max-width: 1055px;
}

.pne-form label {
    color: var(--gray-text);
    font-size: 1.5rem;
}

.pne-input {
    background-color: var(--shadow-gray);
    border: none;
    height: 2.6rem;
    margin: 0 1rem;
    padding: 1rem;
}

.pne-form button {
    display: flex;
    align-items: center;
    background-color: var(--sky-blue);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    color: var(--white);
    font-family: 'Roboto', sans-serif;
    font-size: 0.875rem;
    padding: 0.6rem 2rem 0.5rem 2rem;
    text-transform: uppercase;
}

@media (max-width: 650px) {
    .pne-container {
        padding: 1rem;
    }
    .pne-container {
        text-align: center;
    }
    .pne-form {
        flex-direction: column;
    }
    .pne-form input {
        margin: 1rem;
    }
}